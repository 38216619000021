import React, { useEffect, useState} from 'react'

import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import Fallback from './components/fallback'
import { isAuthenticated } from './services/authentication'

import './assets/styles/global.css'
import 'antd/dist/antd.css'
import Spinner from 'react-spinner-material'

const Login = React.lazy(() => import('./pages/public/login'))
const ForgotPassword = React.lazy(() => import('./pages/public/forgot_password'))
const ChangePassword = React.lazy(() => import('./pages/public/change_password'))
const ActivateAccount = React.lazy(() => import('./pages/public/activate_account'))
const Layout = React.lazy(() => import('./pages/private/layout'))

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
	  	{...rest}

	  	render={props =>isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
			)
	  	}
	/>
)

function App() {
	return (
		<>
			<div className={`loading-overlay hidden`} id="loadingOverlay">
				<div><Spinner radius={50} color={'#fff'} stroke={3} visible={true} /></div>
				<div className='mt-10'>Carregando..</div>
			</div>

			<BrowserRouter>
				<React.Suspense fallback={<Fallback/>}>
					<Switch>
						<Route exact path="/login" render={props => <Login {...props}/>} />
						<Route exact path="/forgot-password" render={props => <ForgotPassword {...props}/>} />
						<Route exact path="/change-password" render={props => <ChangePassword {...props}/>} />
						<Route exact path="/activate" render={props => <ActivateAccount {...props}/>} />

						<PrivateRoute path="/" component={props => <Layout {...props}/>} />
					</Switch>
				</React.Suspense>
			</BrowserRouter>
		</>
	)
}

export default App;
